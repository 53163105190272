const slides = document.querySelectorAll('.slide')
const sliderNav = document.querySelector('.slider__nav')
const sliderActions = document.querySelector('.slider__actions')
let dots = null
let slideIndex = 0;
let intervalId = null;
let intervalTimeout = 5000

document.addEventListener('DOMContentLoaded', initializeSlider)
sliderActions.addEventListener('mouseenter', () => {
    clearInterval(intervalId)
})
sliderActions.addEventListener('mouseleave', () => {
    intervalId = setInterval(nextSlide,intervalTimeout)
})
function initializeSlider() {
    if(!slides.length) {
        return
    }
    createNavItems()
    setNavItemsListener()
    slides[slideIndex].classList.add('is-active')
    dots[slideIndex].classList.add('is-active')
    intervalId = setInterval(nextSlide,intervalTimeout)
}

function setNavItemsListener() {
    dots.forEach((dot, dotIndex) => {
        dot.addEventListener('click', () => {
            goTo(dotIndex)
        })
    })
}

function goTo(index) {
    slideIndex = index
    resetInterval(intervalId)
    showSlide(slideIndex)
}

function createNavItems() {
    if(!slides.length) {
        return
    }

    slides.forEach(slide => {
        const navItem = document.createElement("div");
        navItem.classList.add('dot')
        sliderNav.appendChild(navItem)
    })

    dots = [...sliderNav.querySelectorAll('.dot')]
}

function showSlide(index) {
    if(index >= slides.length ){
        slideIndex = 0
    } else if(index < 0) {
        slideIndex = slides.length -1
    }

    slides.forEach(slide => {
        slide.classList.remove('is-active')

    });
    dots.forEach(dot => {
        dot.classList.remove('is-active')

    });
    slides[slideIndex].classList.add('is-active')
    dots[slideIndex].classList.add('is-active')
}

function nextSlide () {
    slideIndex++
    showSlide(slideIndex)
}

function resetInterval() {
    clearInterval(intervalId)
    intervalId = setInterval(nextSlide, intervalTimeout)
}